/**
 * Custom lodash functionality which is specific to BindHQ
 */
(function () {
    'use strict';

    _.mixin({
        /**
         * Create a namespace from the specified root element
         *
         * @param {Object} curr
         * @param {String} nsString
         *
         * @return {Object}
         */
        ns: function (curr, nsString) {
            _.each(nsString.split('.'), function (part) {
                if (!curr[part]) {
                    curr[part] = {};
                }
                curr = curr[part];
            });

            return curr;
        },

        /**
         * @param {Object} curr
         * @param {String} nsString
         * @param {Object} context
         *
         * @return {Object}
         */
        nsIn: function (curr, nsString, context) {
            const ns = _.ns(curr, nsString);

            _.each(context, function (v, k) {
                ns[k] =
                    _.isFunction(v) && !v.toString().match(/^class /)
                        ? _.bind(v, ns)
                        : v;
            });

            return ns;
        },

        /**
         * Create a partially applied function
         *
         * @param {Function} func
         */
        partial: function (func) {
            const args = Array.prototype.slice.call(arguments, 1);
            return this.bind.apply(this, [func, {}].concat(args));
        },

        /**
         * Function that does nothing and returns false
         *
         * @return {Boolean}
         */
        noop: function () {
            return false;
        },
    });
})();
