const { DateTime } = require('luxon');

(function () {
    'use strict';

    bindhq.ns('util');

    const HTML_TAG_MAPPING = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
    };

    /**
     * @param {String} string
     */
    bindhq.util.escape = function (string) {
        if (string === null) {
            return null;
        }

        return string.replace(/[&<>]/g, function (tag) {
            return HTML_TAG_MAPPING[tag] || tag;
        });
    };

    /**
     * Show a confirmation message before executing the callback
     *
     * @param {Function} callback
     * @param {String} msg
     */
    bindhq.util.confirm = function (callback, msg) {
        const message = msg || 'Are you sure?';

        if (window.confirm(message)) {
            callback.apply(null, Array.prototype.slice.call(arguments, 2));
        }
    };

    /**
     * Create handler that prevents default for an event
     *
     * @param {Function} handler
     * @param {boolean} stopPropagation
     *
     * @return {Function}
     */
    bindhq.util.noDefault = function (handler, stopPropagation) {
        return function (evt) {
            evt.preventDefault();

            if (stopPropagation) {
                evt.stopPropagation();
            }

            return handler(evt);
        };
    };

    /**
     * Makes a POST request to the specified URL
     *
     * @param {String} url
     */
    bindhq.util.post = function (url) {
        $('<form></form>')
            .attr({
                method: 'post',
                action: url,
            })
            .css({ display: 'none' })
            .appendTo('body')
            .trigger('submit');
    };

    /**
     * Returns the current users ID
     *
     * @return {Number}
     */
    bindhq.util.userId = function () {
        const classes = $('body').attr('class');
        const matches = classes.match(/user-(\d+)/);

        if (matches) {
            return parseInt(matches[1], 10);
        }
    };

    /**
     * Display a warning message in the log
     *
     * @param {String} message
     */
    bindhq.util.warn = function (message) {
        if (window.console && console.warn) {
            console.warn(message);
        }
    };

    /**
     * Basic handling of ajax errors
     */
    bindhq.util.ajaxError = function (data, jqXHR) {
        let message;

        try {
            data = $.parseJSON(jqXHR.responseText);
            message = data.message;
        } catch (err) {
            message = 'An error occured.';
        }

        bindhq.loader.hide();

        swal(message);
    };

    /**
     * Set the document fragment
     *
     * @param {String} hash
     */
    bindhq.util.setHash = function (hash) {
        history.pushState(null, null, hash);
    };

    /**
     * Fire an event from an object with some optional parameters
     *
     * @param {Object} firingObject The owner of the event
     * @param {String} eventName Name of the event. Can be standard JS/DOM or custom
     * @param {Object} paramObject (optional) Parameters to be passed with the event
     */
    bindhq.util.fireEvent = function (firingObject, eventName, paramObject) {
        paramObject = typeof paramObject === 'undefined' ? {} : paramObject;
        const ev = $.Event(eventName, paramObject);
        $(firingObject).trigger(ev);
    };

    /**
     * Provides robust email address validation by regex.
     *
     * @param {String} emailAddress A string holding the email address to be validated
     *
     * @return {boolean} True for valid.
     */
    bindhq.util.isValidEmail = function (emailAddress) {
        const emailCheck =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        return emailCheck.exec(emailAddress) !== null;
    };

    /**
     * Function which does nothing
     *
     */
    bindhq.util.noop = function () {};

    /**
     * Pad tens onto a number if needed
     *
     * @param {Number} num
     *
     * @return {String}
     */
    bindhq.util.padTens = function (num) {
        const val = Math.abs(num);

        return val < 10 ? '0' + val : val;
    };

    /**
     * Extract sortable text from a table cell
     *
     * @param {Element} node
     *
     * @return {String}
     */
    bindhq.util.tableSortText = function (node) {
        const children = node.childNodes;
        const text = children.childNodes
            ? children.childNodes[0].innerText
            : node.innerText;

        if (!text) {
            return '';
        }

        return text.replace(/[$,]/g, '');
    };

    /**
     * @param {String} text
     *
     * @return {Object|boolean}
     */
    bindhq.util.jsonParse = function (text) {
        try {
            return JSON.parse(text);
        } catch (e) {
            return false;
        }
    };

    /**
     * @param {String} date
     *
     * @return {String}
     */
    bindhq.util.toDate = function (date) {
        return DateTime.fromISO(date).toFormat('MM/dd/y');
    };

    /**
     * @param {String} name
     *
     * @return {String|null}
     */
    bindhq.util.queryParam = function (name) {
        return new URLSearchParams(window.location.search).get(name);
    };

    /**
     * @param {Mixed} data
     * @param {String} contentType
     * @param {String} filename
     */
    bindhq.util.download = function (data, contentType, filename) {
        const blob = new Blob([data], { type: contentType, endings: 'native' });
        const URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = jQuery.isPlainObject(data) ? data.file : downloadUrl;
        a.download = jQuery.isPlainObject(data) ? data.filename : filename;

        document.body.appendChild(a);

        a.click();
        a.remove();
    };

    /**
     * @param {String} entity
     * @param {String} id
     *
     * @return {String|null}
     */
    bindhq.util.ornForEntity = function (entity, id) {
        if (id) {
            const client = $('body').data('orn-client');

            return (
                'orn:owsy:bindhq:' +
                client +
                ':bindhq-webapp:' +
                entity +
                ':' +
                id
            );
        }

        return null;
    };

    /**
     * @param {Integer} total
     * @param {Integer} perPage
     *
     * @return {Integer}
     */
    bindhq.util.getTotalPages = function (total, perPage) {
        return Math.ceil(total / perPage);
    };

    /**
     * @param {Object}
     */
    bindhq.util.ajaxDefaults = {
        xhrFields: {
            withCredentials: true,
        },
    };

    /**
     * @param {Object} config
     *
     * @return {Object}
     */
    bindhq.util.ajax = function (config) {
        return $.ajax(_.extend({}, bindhq.util.ajaxDefaults, config));
    };

    /**
     * Create a basic v4 UUID (with randomness caveats as explained below)
     * https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
     */
    bindhq.util.uuid4 = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            },
        );
    };

    /**
     * @param {String} tpl
     * @param {Object} params
     *
     * @return {String|Function}
     */
    bindhq.util.template = function (tpl, params) {
        if (!tpl) {
            throw new Error('Javascript template cannot be empty');
        }

        return _.template(tpl, params);
    };

    /**
     * @param {DOMElement} element
     *
     * @return {String}
     */
    bindhq.util.getPathTo = function (element) {
        if (element.tagName === 'HTML') {
            return 'html';
        }

        let selector = element.tagName.toLowerCase();

        const classes = element.classList.toString().split(' ').join('.');
        const siblings = element.parentNode.children;

        if (element.id) {
            selector += '#' + element.id;
            return selector;
        }

        if (classes) {
            selector += '.' + classes;
        }

        if (siblings.length > 1) {
            const index = Array.prototype.indexOf.call(siblings, element);
            selector += ':nth-child(' + (index + 1) + ')';
        }

        return bindhq.util.getPathTo(element.parentNode) + ' > ' + selector;
    };
})();
