/* global global */

(function () {
    'use strict';

    window.bindhq.ns = _.partial(_.ns, window.bindhq);
    window.bindhq.nsIn = _.partial(_.nsIn, window.bindhq);

    if (typeof jasmine === 'object') {
        global.bindhq = window.bindhq;
    }
})();
